<template>
    <b-row class="match-height">
      <b-col
lg="8"
offset="2"
> <b-card
      title="Supplier Detail"
    >
        <b-row>
          <b-col cols="12">

            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <div class="devider" />
        <b-form-group>
          <label for="email">Name :</label>
          <input
v-model="contactName"
type="text"
name="Number"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">Category :</label>
          <input
v-model="contactCategory"
type="text"
name="Number"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">Type :</label>
          <input
v-model="contactType"
type="text"
name="Number"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">Address :</label>
          <input
v-model="contactAddress"
type="text"
name="to"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="email">City :</label>
          <input
v-model="contactCity"
type="text"
name="cc"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Postal Code :</label>
          <input
v-model="contactPostalCode"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Phone 1:</label>
          <input
v-model="contactPhone1"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Phone 2:</label>
          <input
v-model="contactPhone2"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Fax:</label>
          <input
v-model="contactFax"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Email:</label>
          <input
v-model="contactEmail"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">PIC Name:</label>
          <input
v-model="contactPicName"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">PIC Phone:</label>
          <input
v-model="contactPicPhone"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Bank 1:</label>
          <input
v-model="contactBank1"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Bank 2:</label>
          <input
v-model="contactBank2"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Bank 3:</label>
          <input
v-model="contactBank3"
type="text"
name="reason"
class="form-control"
readonly
>
        </b-form-group>
          </b-col>
        </b-row>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const contactAddress = ''
  const contactCity = ''
  const contactPostalCode = ''
  const contactQtyRequested = 0
  const contactStatus = ''
  const contactId = ''
  const contactName = ''
  const contactCategory = ''
  const contactType = ''
  const materialrequestIsAcceptedBySupervisor = 0
  const materialrequestIsAcceptedByWarehouse = 0
  const materialrequestIsReceived = 0
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      contactAddress,
      contactCity,
      contactPostalCode,
      contactQtyRequested,
      contactStatus,
      contactId,
      contactName,
      contactCategory,
      contactType,
      materialrequestIsAcceptedBySupervisor,
      materialrequestIsAcceptedByWarehouse,
      materialrequestIsReceived,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
    getDetail() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      const item = this.$cookies.get('contactDetail')
      this.modalDetailShow = true
      this.contactAddress = item.contact_address
      this.contactCity = item.contact_city
      this.contactPostalCode = item.contact_postal_code
      this.contactId = item.contact_id
      this.contactName = item.contact_name
      this.contactCategory = item.contact_category
      this.contactType = item.contact_type
      this.contactPhone1 = item.contact_phone_1
      this.contactPhone2 = item.contact_phone_2
      this.contactFax = item.contact_fax
      this.contactEmail = item.contact_email
      this.contactPicName = item.contact_pic_name
      this.contactPicPhone = item.contact_pic_phone
      this.contactBank1 = item.contact_bank_1
      this.contactBank2 = item.contact_bank_2
      this.contactBank3 = item.contact_bank_3
      const id = item.contact_id
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.items = response.data.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    previous() {
          this.$router.push({ name: 'apps-supplier-list' })
    },
    },
  }
  </script>